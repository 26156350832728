import Loader from 'react-loader-spinner'
import React from 'react';
const LoaderComponent = () => {
   return (
      <Loader
         type="TailSpin"
         color="#66b6e0"
         className="set-loader"
      />
   );
}
export default LoaderComponent
