import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loader from '../src/Views/Loader/Loader';
const LoginPage = React.lazy(() => import('./Views/Login/Login'));
//const Wrongpage = React.lazy(() => import('./Views/404Page/404Page'));
const ResetPassword = React.lazy(() => import('./Views/ResetPassword/ResetPassword'));
const ForgotPassword = React.lazy(() => import('./Views/ForgotPassword/ForgotPassword'));
const SignupPage = React.lazy(() => import('./Views/Signup/Signuppage'));
const DefaultLayout = React.lazy(() => import('./Views/DefaultLayout'));
const loading = () => <Loader></Loader>;
const App = () => {
  return (
    <div>
      <React.Suspense fallback={loading()} >
        <Switch>
          <Route exact path="/" name="Login Page" render={props => <LoginPage {...props} />} />
          <Route exact path="/signup-page" name="Sign up Page" render={props => <SignupPage {...props} />} />
          <Route exact path="/reset-password" name="Reset Password" render={props => <ResetPassword {...props} />} />
          <Route exact path="/forgot-password/:token" name="Forgot Password" render={props => <ForgotPassword {...props} />} />
          {/* <Route exact={true} title="404Page" component={Wrongpage} />  */}
          <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
        </Switch>
      </React.Suspense>
    </div>
  );
}
export default App
